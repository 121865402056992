<template lang="pug">
  .page.options-page
    el-form.page__container.options-page__container(ref="postForm" :model="postForm" :rules="rules" label-width="200px")
      sticky.page__line(:class-name="'sub-navbar'")
        el-button(
          v-loading="loading"
          style="margin-left: 10px;"
          type="success"
          @click="submitForm"
        ) Сохранить

      .page__body(v-loading="dataLoading")
        el-row
          el-col(:span="24")
            el-form-item(label="Роль" prop="role") {{ user.role_name }}

            el-form-item(label="Имя" prop="name") {{ user.name }}

            el-form-item(label="E-mail" prop="email") {{ user.email }}


            el-form-item(label="Срок действия пароля" prop="password_expiration_date") {{ user.password_expiration_date }}

            el-form-item(label="Старый пароль" prop="oldPassword")
              el-input(v-model="postForm.oldPassword" show-password)

            el-form-item
              el-button(@click="generatePassword" type="primary") Сгенерировать пароль


            el-form-item(label="Пароль" prop="password")
              el-input(v-model="postForm.password" show-password)

            el-form-item(label="Подтвердите пароль" prop="confirmPassword")
              el-input(v-model="postForm.confirmPassword" show-password)


            el-form-item(label="Пароль должен:")
              ul
                li(v-if="securitySettings.passwordRequireUpperLetters") содержать не меньше 1 буквы в верхнем регистре
                li(v-if="securitySettings.passwordRequireLowerLetters") содержать не меньше 1 буквы в нижнем регистре
                li(v-if="securitySettings.passwordRequireNumbers") содержать не меньше 1 цифры
                // li(v-if="securitySettings.passwordRequireUpperLetter") содержать не меньше 1 специального символа
                li(v-if="securitySettings.passwordMinLength") иметь длину не менее {{ securitySettings.passwordMinLength }} символов
                li(v-if="securitySettings.passwordMaxLength") иметь длину не более {{ securitySettings.passwordMaxLength }} символов

            // el-form-item(label="Разрешения" prop="permissions")
              user-permissions(v-model="postForm.permissions" :rolePermissions="rolePermissions")

</template>

<script>

  // import FieldsForm from '@/components/fields/FieldsForm.vue';
  import Sticky from '@/components/Sticky'
  import UserPermissions from './UserPermissions'

  // import {validURL} from '@/utils/validate';
  import { default as request, catchRequest, notifyRequest } from '@/utils/api'

  export default {
    name: 'ChangeMyPassword',
    components: {
      Sticky,
      UserPermissions
    },
    data() {
      const validateRequire = (rule, value, callback) => {
        if (value === '') {
          this.$message({
            message: rule.field + ' is required',
            type: 'error',
          })
          callback(new Error(rule.field + ' is required'))
        } else {
          callback()
        }
      }
      const validateConfirmPassword = (rule, value, callback) => {
        if (value !== this.postForm.password) {
          callback(new Error('Password is mismatched!'))
        } else {
          callback()
        }
      }
      const user = this.$store.state.user.user
      return {
        securitySettings: this.$store.state.settings.security,
        user,
        postForm: {
          oldPassword: '',
          password: '',
          confirmPassword: ''
        },
        loading: false,
        dataLoading: false,
        fields: [],
        rules: {
          password: [{ required: true, message: 'Пароль - обязательное поле', trigger: 'blur' }],
          // password_validation_date: [
          //   { required: true, message: 'Срок действия пароля - обязательное поле', trigger: 'blur' }
          // ],
          confirmPassword: [{ validator: validateConfirmPassword, trigger: 'blur' }],
        },
        tempRoute: {},
      }
    },
    computed: {
      lang() {
        return this.$store.getters.language
      }
    },
    async created() {
      // Why need to make a copy of this.$route here?
      // Because if you enter this page and quickly switch tag, may be in the execution of the setTagsViewTitle function, this.$route is no longer pointing to the current page
      this.tempRoute = Object.assign({}, this.$route)
    },
    methods: {
      async generatePassword() {
        const response = await notifyRequest({
          url: 'generate-password',
          method: 'get'
        }, 'Пароль успешно сгенерирован и вставлен')
        const password = response.password
        this.postForm.password = password
        this.postForm.confirmPassword = password
      },
      async storeData(data) {
        const response = await notifyRequest({
          url: 'change-password',
          method: 'put',
          data: data,
        }, 'Пароль успешно изменен')
        return response
      },
      setTagsViewTitle() {
        const route = Object.assign({}, this.tempRoute)
        this.$store.dispatch('updateVisitedView', route)
      },
      async submitForm() {
        console.log(this.postForm)
        this.$refs.postForm.validate(valid => {
          if (valid) {
            this.loading = true
            this.storeData(this.postForm)
            /* this.$notify({
              title: 'Успешно!',
              message: 'Все настройки успешно сохранены',
              type: 'success',
              duration: 2000,
            });*/
            // this.postForm.status = 'published';
            this.loading = false
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>
<style lang="sass">
  @import "@/assets/sass/mixin.scss"
</style>
